import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../../components/PageLayout"
import PostPreview from "../../components/PostPreview"
import PageHeader from "../../components/PageHeader"
import PropTypes from "prop-types"
import { getSrc } from "gatsby-plugin-image"

const Blog = ({ data }) => {
  const posts = data.allContentfulBlogPost.nodes
  const metaImage = getSrc(posts[0].coverImg.ogImage)
  return (
    <PageLayout
      title="Blog"
      contentPath="blog"
      metaImage={metaImage}
      metaDescription="Home of composer Tim Sandberg's Personal Blog"
    >
      <PageHeader title="Blog" />
      <div className="row">
        <div className="col-12" style={{ marginBottom: "1rem" }}>
          <PostPreview key={posts[0]} post={posts[0]} />
        </div>
        {/* TODO: Make these verticle cards for desktop view */}
        {posts.slice(1).map((post) => (
          <PostPreview post={post} key={post.id} small={true} />
        ))}
      </div>
    </PageLayout>
  )
}

export const query = graphql`
  {
    allContentfulBlogPost(sort: { order: DESC, fields: date }) {
      totalCount
      nodes {
        id
        slug
        coverImg {
          gatsbyImageData(layout: CONSTRAINED)
          ogImage: gatsbyImageData(width: 1200, height: 627, cropFocus: CENTER)
        }
        body {
          childMarkdownRemark {
            excerpt
          }
        }
        title
        coverAlt
        date(formatString: "MMM Do, YYYY")
        teaser
      }
    }
  }
`

Blog.propTypes = {
  data: PropTypes.any,
}

export default Blog
