import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import PropTypes from "prop-types"

const PostPreview = ({ post, small = false }) => (
  <div className="row" style={{ marginBottom: "1rem" }}>
    <div
      className={`col-xs-12 ${small && "small"}`}
      style={{ fontSize: small ? "1rem" : "1.1rem" }}
    >
      <Link to={post.slug} key={post.id}>
        <div className="row gy-3">
          <div className="col-12 col-sm-4">
            <GatsbyImage
              image={post.coverImg.gatsbyImageData}
              alt={`Cover image for ${post.title}`}
              style={{ height: small && "200px" }}
            />
          </div>
          <div
            className="col-xs-12 col-sm-7"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <small>{post.date}</small>
            <h2 style={{ marginBottom: small ? "0.5rem" : "1rem" }}>
              {post.title}
            </h2>
            <p
              className="small"
              dangerouslySetInnerHTML={{
                __html: post.body.childMarkdownRemark.excerpt,
              }}
            ></p>
          </div>
        </div>
      </Link>
    </div>
  </div>
)

PostPreview.propTypes = {
  post: PropTypes.object.isRequired,
  small: PropTypes.bool,
}

export default PostPreview
